.webtemplate {
  .map {
    border: 0;
    width: 100%;
    height: 6rem;
  }

  .donate {
    align-items: center;
    a {
      margin: 0 auto;
    }
  }

  .footerSection {
    li {
      padding: 0;
      margin: 0;
      margin-inline: 0;
    }
  }
}
