@import '../../colors.module.scss';

.webtemplate {
  width: 100%;
  padding: 0;
  margin: 0;
  background: $backgroundPrimary;
  background-size: 400% 400%;
  min-height: 100vh;

  .templateheader {
    width: 100%;
    height: auto;
    padding: 0;
    margin: 0;
    position: sticky;
    top: 0;
    z-index: 100;
    padding-bottom: 0.4rem;
    border-bottom: 1px solid $backgroundSecondary;
    background: $backgroundGradientPrimary;
    .templatenav {
      width: 100%;
      padding: 0 4%;
      line-height: 50px;
    }
  }

  .banner {
    display: flex;
    width: 100%;
    text-align: left;
    padding: 0 10%;
    background: url(../../images/backgroundBanner.png) repeat;
    .bannerImage {
      max-height: 350px;
    }
    .bannerText {
      flex: 1;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 30%;
      padding-bottom: 75px;
    }
  }

  .discountText {
    margin-bottom: 2rem;
    color: $highlightPrimary;
    font-size: 1rem;
    font-weight: bold;
  }

  .bannerTextMobile {
    display: none;
  }

  .divider {
    margin: 1rem 0 0;
  }

  .templatecontent {
    width: 92%;
    margin: 0 4%;
    display: flex;
    text-align: center;
    min-height: calc(100vh - 316px);
    .page {
      flex: 3;
      padding: 0;
    }
    .sidebar {
      flex: 1;
      padding: 1.25rem;
    }
  }

  .footer {
    background: $backgroundSecondary;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 0;
    > div {
      padding: 0 1.25rem;
      flex: 1;
      display: flex;
      justify-content: center;
      height: 200px;
    }
    .ant-typography h4 {
      color: $backgroundTertiary;
    }
    .ant-typography {
      color: #fff;
      text-align: justify;
    }
    ul {
      list-style-type: disc;
      color: $backgroundTertiary;
    }
    a {
      color: #fff;
      text-decoration: none !important;
      text-transform: uppercase;
    }
    .icon {
      margin-right: 0.5rem;
      color: $backgroundTertiary;
    }
    .footerSection {
      ul {
        text-align: left;
      }
      > div {
        text-align: center;
      }

      .footerTitle {
        text-align: center !important;
      }
      .footercontact {
        padding-bottom: 1rem;
      }
    }
  }

  .copyright {
    background: $backgroundTertiary;
    color: $backgroundSecondary;
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    font-weight: bold;
  }
  .webButton {
    color: #fff;
    white-space: nowrap;
    background-color: $backgroundSecondary !important;
    padding: 10px 20px;
    border-radius: 25px;
    display: inline-block;
    cursor: pointer;
    text-decoration: none !important;
  }
}

.webtemplate.mobile {
  .bannerText {
    display: none !important;
  }
  .bannerTextMobile {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .discountText {
      margin: 1rem 0;
    }
  }

  .templatenav {
    padding: 0 12px !important;
  }

  .footer {
    flex-direction: column;
    .footerSection {
      ul {
        text-align: center;
        list-style-type: none;
      }
    }
    div {
      text-align: center;
    }
  }
}

h3.ant-typography {
  border-bottom: 1px solid $textSecondary !important;
}
