@import '../../../colors.module.scss';

.webtemplate {
  .header {
    display: flex;
    justify-content: space-between;
    .logo {
      color: $textPrimary !important;
      text-decoration: none !important;
      font-family: 'cinzel';
      font-size: 1.5rem;
      max-width: 200px;
      min-width: 100px;
    }
    .titleCity {
      color: $highlightPrimary;
      white-space: nowrap;
      font-weight: bold;
      position: relative;
      left: -9px;
      top: -4px;
    }
    .menu {
      display: flex;
      font-size: 1rem;
      text-transform: uppercase;
      letter-spacing: 1px;
      width: calc(92% - 220px);
      justify-content: flex-end;
    }
  }
}
