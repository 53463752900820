@import './colors.module.scss';

// css for html elements
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Poppins', 'Segoe UI',
    'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: underline !important;
  margin: 0 0.5rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

q,
b {
  color: $highlightPrimary !important;
  font-style: italic;
  font-weight: bold;
}
.link {
  color: $linkPrimary;
  padding: 0 5px;
  cursor: pointer;
}
// css for ant classes

.ant-steps-item-process .ant-steps-item-content div {
  color: $linkActivePrimary !important;
}

.ant-menu-horizontal {
  border-bottom: none;
  background: transparent !important;
  .ant-menu-title-content {
    color: #fff !important;
  }
  .ant-menu-item-selected span {
    color: $highlightPrimary !important;
    font-weight: bold;
    text-underline-offset: 3px;
  }
}
.ant-menu-overflow-item-rest {
  color: #fff !important;
}

.ant-menu a {
  text-decoration: none !important;
}

.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: 0px !important;
  transition: none !important;
}
.ant-menu-item-active {
  color: $textTertiary !important;
}

.ant-typography {
  color: $textPrimary;
}

.ant-modal {
  .ant-modal-title {
    margin-bottom: 24px;
    border-bottom: 1px solid;
    padding-bottom: 3px;
  }
  .ant-form-item {
    margin-bottom: 12px;
  }
}

.ant-input-number {
  width: 100%;
}
