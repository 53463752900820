@import '../../colors.module.scss';

.orderNowButton {
  color: #fff;
  white-space: nowrap;
  background-color: $backgroundSecondary !important;
  padding: 10px 20px;
  border-radius: 25px;
  display: inline-block;
  cursor: pointer;
  min-width: 60%;
}
