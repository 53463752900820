@import '../../colors.module.scss';

.customerFeedbackTitle {
  margin-bottom: 1.2rem;
}

.customerFeedback {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  .feedback {
    text-align: left;
    padding: 1rem 1rem 0.5rem;
    margin: 0 0.5rem;
    background: #f0f0f0;
    border-radius: 10px;
    .feedbackTitle {
      font-size: 1rem;
      font-weight: bold;
      color: $highlightPrimary;
      margin-bottom: 0.25rem;
    }
    .feebackUser {
      color: $textSecondary;
      .anticon {
        margin-left: 6px;
        color: gold;
      }
    }
    p {
      text-align: justify;
    }
  }
}

.mobile {
  .customerFeedback {
    flex-direction: column;
    .feedback {
      margin-bottom: 1rem;
    }
  }
}
