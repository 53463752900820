@import '../../colors.module.scss';

.news {
  padding-bottom: 0 !important;
  margin-bottom: 1.5rem !important;
  .date {
    float: right;
    color: $textSecondary;
    font-weight: bold;
  }
}
