@import '../../colors.module.scss';

.pagetext {
  text-align: justify;
}
.orderOr {
  margin-top: 1rem;
}
.orderTitle {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
  color: $highlightPrimary;
}
.orderLink {
  font-size: 0.9rem;
  font-weight: bold;
}

.extraLinks {
  margin-top: 1rem;
  a {
    color: $textSecondary;
  }
}
