@import '../../colors.module.scss';

.FaqsTitle {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem !important;
  color: $highlightPrimary;
}

.Faqs {
  text-align: left;
  margin-bottom: 1.5rem !important;
}
