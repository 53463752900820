@import '../../colors.module.scss';

.WorkStepsTitle {
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
  color: $highlightPrimary;
}

.WorkSteps {
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  .step {
    flex: 1;
    text-align: center;
    padding: 1rem 1rem 0.5rem;
    margin: 0 0.5rem;
    border-radius: 10px;
    .stepImage {
      border-radius: 50%;
      width: 60%;
    }
    .stepTitle {
      font-size: 1rem;
      font-weight: bold;
      margin: 1rem 0;
      color: $highlightPrimary;
    }
    p {
      text-align: center;
    }
  }
}

.mobile {
  .WorkSteps {
    flex-direction: column;
    .step {
      margin: 0;
    }
  }
}
