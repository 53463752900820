$backgroundPrimary: #fff;
$backgroundSecondary: #6a0005;
$backgroundTertiary: #fff;
$backgroundGradientPrimary: linear-gradient(
  90.18deg,
  $backgroundSecondary 0.07%,
  #6a0005 100%
);
$backgroundGradientSecondary: linear-gradient(
  -45deg,
  #23d5ab,
  #23a6d5,
  #e73c7e,
  #ee7752
);

$textPrimary: rgba(0, 0, 0, 0.88);
$textSecondary: #ab4b38;
$textTertiary: #ab4b38;

$linkPrimary: #38ab79;
$linkSecondary: #38ab79;
$linkActivePrimary: #ab4b38;
$linkActiveSecondary: #ab4b38;

$headingPrimary: #ab4b38;
$headingSecondary: #ab4b38;
$highlightPrimary: #38ab79;
$highlightSecondary: #38ab79;

$borderColor: #e8e8e8;

:export {
  backgroundPrimary: $backgroundPrimary;
  backgroundSecondary: $backgroundSecondary;
  backgroundTertiary: $backgroundTertiary;
  backgroundGradientPrimary: $backgroundGradientPrimary;
  backgroundGradientSecondary: $backgroundGradientSecondary;
  textPrimary: $textPrimary;
  textSecondary: $textSecondary;
  textTertiary: $textTertiary;
  linkPrimary: $linkPrimary;
  linkSecondary: $linkSecondary;
  linkActivePrimary: $linkActivePrimary;
  linkActiveSecondary: $linkActiveSecondary;
  headingPrimary: $headingPrimary;
  headingSecondary: $headingSecondary;
  highlightPrimary: $highlightPrimary;
  highlightSecondary: $highlightSecondary;
}
