@import '../../../colors.module.scss';
.webtemplate {
  .news {
    text-align: left;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid $borderColor;
    a {
      margin-left: 0;
      padding-left: 0;
    }
  }
}
