@import '../../colors.module.scss';

.contact {
  display: flex;
  flex-direction: column;
  margin: 1.5rem 0 0rem;

  .contactContact {
    font-size: 1.2rem;
    .contactTitle {
      color: $textSecondary;
      font-weight: bold;
      margin-right: 1rem;
    }
  }
  .map {
    border: 0;
    width: 100%;
    height: 100%;
  }
}

.mobile {
  .contact {
    display: flex;
    flex-direction: column;
  }
}
